<template>
  <div class="events" v-if="events">
    <div
      v-if="events && events.length"
      class="grid"
    >
      <!-- баннер -->
      <!-- <banner-guest-card /> -->

      <event
        v-for="event in events"
        :key="event.id"
        :event="event"
        @click.native="goToEvent(event)"
      />
    </div>
    <div v-else class="events-plug">
      Ничего не найдено
    </div>
    <div class="tags">
      <swiper
        :options="{ 
          slidesPerView: 'auto',
          touchRatio: 2
        }"
      >
        <swiper-slide>
          <tag
            title="Все"
            :active="activeBadge === 0"
            @click.native="changeBadge(0)"
          />
        </swiper-slide>
        <swiper-slide
          v-for="(tag, index) in cities"
          :key="index"
        >
          <tag
            :title="tag.title"
            :active="tag.id === activeBadge"
            @click.native="changeBadge(tag.id)"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

import Tag from "@/components/Parts/Tag";
import Event from "@/components/Parts/EventCard";
import BannerGuestCard from "@/components/Parts/BannerGuestCard";

export default {
  name: "Events",
  props: {
    limit: {
      type: Number,
      default: 0
    }
  },
  components: {
    Event,
    Tag,
    BannerGuestCard
  },
  data() {
    return {
      badges: [],
      activeBadge: 0,
      cities: [],
      events: []
    };
  },
  watch: {
    activeBadge(id) {
      if (id === 0) {
        this.getAllEvents();
      }

      api.getEventCity(id).then(res => {
        this.events = res.data;
      });

      // api.getEvents(null, null, null, id).then(res => {
      //   this.$store.commit("setEvents", res.data);
      // });
    }
  },
  mounted() {
    this.getAllEvents();

    api.getBadges().then(res => {
      this.badges = res.data;
    });

    api.getCity().then(res => {
      this.cities = res.data.sort((a, b) => a.id - b.id)
    })
  },
  methods: {
    goToEvent(event) {
      this.$router.push({ path: `/event/${event.id}` });
      this.$store.dispatch("setSelectedEvent", event);
    },

    changeBadge(id) {
      this.activeBadge = id;
    },

    getAllEvents() {
      api.getAllEvents().then(res => {
        this.events = res.data
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";
.tags {
  position: absolute;
  bottom: 160px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.events {
  .grid {
    margin: 0 32px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .event {
      margin-bottom: 84px;
    }
  }

  .events-plug {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1052px;
    font-size: 56px;
  }
}
</style>